import { Component, OnInit } from '@angular/core';

import { TranslatorService } from '@app/core/translator/translator.service';

@Component({
  selector: 's1-language',
  templateUrl: './s1-language.component.html',
  styleUrls: ['./s1-language.component.scss']
})
export class S1Language implements OnInit {

  langs = [];

  constructor(public translator: TranslatorService ) { }

  ngOnInit(): void {
    this.langs = this.translator.getAvailableLanguages();
  }

  setLang(value) {
    this.translator.useLanguage(value);
  }

}