import { Component, Input, OnInit } from '@angular/core';
import { Status } from '@app/shared/models/status';

@Component({
  selector: 'leu-center-status-badge',
  templateUrl: './center-status-badge.component.html',
  styleUrls: ['./center-status-badge.component.scss']
})
export class CenterStatusBadgeComponent implements OnInit {

  @Input() status: Status;

  styles: { [key in Status] : string } = {
    ACTIVE: "badge-success text-white",
    DISABLED: "badge-danger text-white"
  }

  constructor() { }

  ngOnInit(): void {
  }

}
