<s1-modal #modal [title]="'transactions.modal.title'">
  <s1-table #table [onlinePagination]="true" (pageChanged)="search()">
    <thead header>
      <tr>
        <th>#</th>
        <th class="text-right">{{ 'transactions.modal.table.columns.amount' | translate }}</th>
        <th class="text-right">{{ 'transactions.modal.table.columns.realAmount' | translate }}</th>
        <th>{{ 'transactions.modal.table.columns.dateCreated' | translate }}</th> 
        <th>{{ 'transactions.modal.table.columns.dateModified' | translate }}</th>
        <th>{{ 'transactions.modal.table.columns.status' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody body>
      <tr *ngFor="let transaction of table.config.rows; let i = index">
        <td>{{ i | s1TableGeneralIndex: table }}</td>
        <td class="text-right">{{ transaction.amount | s1Currency: true }}</td>
        <td class="text-right">{{ (transaction.realAmount | s1Currency: true) ?? '-' }}</td>
        <td>{{ transaction.tsCreated | s1ReadableDate }}</td>
        <td>{{ transaction.tsLastModified | s1ReadableDate }}</td>
        <td><leu-transaction-status-badge [status]="transaction.status"></leu-transaction-status-badge></td>
        <td class="text-right">
          <s1-button *ngIf="transaction.status == transactionStatus.PENDING" [type]="s1ButtonType.Check" [onlyIcon]="true" (onClick)="askConfirmation(transaction, true)"></s1-button>
          <s1-button class="ml-3" *ngIf="transaction.status == transactionStatus.PENDING || transaction.status == transactionStatus.ACTIVE" [type]="s1ButtonType.Uncheck" [onlyIcon]="true" (onClick)="askConfirmation(transaction, false)"></s1-button>
        </td>
      </tr>
    </tbody>
  </s1-table>
</s1-modal>