import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { S1ButtonType } from '@app/s1/components/s1-button/s1-button.component';

enum ServicePayResult {
  OK = "OK",
  KO = "KO"
}

@Component({
  selector: 'leu-service-pay-result',
  templateUrl: './service-pay-result.component.html',
  styleUrls: ['./service-pay-result.component.scss']
})
export class ServicePayResultComponent implements OnInit {

  s1ButtonType = S1ButtonType;
  servicePayResult = ServicePayResult;

  result: ServicePayResult = ServicePayResult.KO;
  transactionId: string;

  constructor(private route: ActivatedRoute, private router: Router) { 

    this.route.queryParamMap.subscribe(params => {
      this.result = params.get('result') as ServicePayResult;
      const milliseconds = params.get('milliseconds');
      this.transactionId = params.get('transactionId');

    });

  }

  ngOnInit(): void { }

  home() {
    this.router.navigate(['/']);
  }

}
