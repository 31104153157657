import { Injectable } from '@angular/core';
import { IS1PaginatedResultJava, IS1SearchParamsJava, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICustomer } from '../models/customer';
import { IWallet } from '../models/wallet';
import { ICard } from '../models/cards';
import { ITransaction } from '../models/transaction';

export interface ICustomersPaginati extends IS1PaginatedResultJava {
  customers: ICustomer[]
}

enum CustomerApi {
  list = "/list",
  wallet = "/wallet",
  cards = "/cards",
  transactions = "/wallet/transactions"
}

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  private path = "/customer"

  constructor(private s1HttpClient: S1HttpClientService) { }

  search(params: IS1SearchParamsJava): Observable<ICustomersPaginati> {

    return this.s1HttpClient.post(`${this.path}${CustomerApi.list}`, params).pipe(
      map(response => {
        return {
          customers: response.results,
          paginationInfo: { totalItems: response.total }
        } as ICustomersPaginati
      })
    )

  }

  detail(customerId: number): Observable<ICustomer> {

    return this.s1HttpClient.get(`${this.path}/${customerId}`).pipe(
      map(response => response.data)
    )

  }

  wallet(customerId: number): Observable<IWallet> {

    return this.s1HttpClient.get(`${this.path}/${customerId}${CustomerApi.wallet}`).pipe(
      map(response => response.data)
    )

  }

  cards(customerId: number): Observable<ICard[]> {

    return this.s1HttpClient.get(`${this.path}/${customerId}${CustomerApi.cards}`).pipe(
      map(response => response.results)
    )

  }

  transactions(customerId: number): Observable<ITransaction[]> {

    return this.s1HttpClient.get(`${this.path}/${customerId}${CustomerApi.transactions}`).pipe(
      map(response => response.results)
    )

  }


}
