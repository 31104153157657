import { Injectable } from '@angular/core';
import { IS1PaginatedResultJava, IS1SearchParamsJava, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ITransaction, TransactionStatus, TransactionType } from '../models/transaction';

export interface IRechargeParams {
  amount: number,
  idCenter: number,
  type: TransactionType
}

export interface IServicePayPayment {
  paymentID: string,
  securityToken: string,
  hostedPageUrl: string,
  customField: string,
  splitPaymentIDs: [
      {
          merchantCode: string,
          salePaymentID: string,
          installmentPaymentID: string
      }
  ]
}

export interface ITransactionsPaginati extends IS1PaginatedResultJava {
  transactions: ITransaction[]
}

export interface IChangeStatusParams {
  id: number,
  status: TransactionStatus
}

enum TransactionApi {
  list = "/list",
  status = "/status",
  update = "",
  remove = ""
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private path = "/transaction"

  constructor(private s1HttpClient: S1HttpClientService) { }

  search(params: IS1SearchParamsJava): Observable<ITransactionsPaginati> {

    return this.s1HttpClient.post(`${this.path}${TransactionApi.list}`, params).pipe(
      map(response => {
        return {
          transactions: response.results,
          paginationInfo: { totalItems: response.total }
        } as ITransactionsPaginati
      })
    )

  }

  recharge(params: IRechargeParams): Observable<boolean> {
    return this.s1HttpClient.post(this.path, params).pipe(
      map(response => response.outcome.success)
    ) 
  }

  rechargeServicePay(params: IRechargeParams): Observable<IServicePayPayment> {
    return this.s1HttpClient.post(this.path, params).pipe(
      map(response => response.data)
    ) 
  }

  changeStatus(params: IChangeStatusParams): Observable<boolean> {
    return this.s1HttpClient.put(this.path + TransactionApi.status, params).pipe(
      map(response => response.outcome.success)
    )
  }

}
