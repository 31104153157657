<s1-table #table [onlinePagination]="false">
  <thead header>
    <tr>
      <th>#</th>
      <th>{{ 'transactions.list.table.columns.dateCreated' | translate }}</th> 
      <th>{{ 'transactions.list.table.columns.dateModified' | translate }}</th>
      <th class="text-right">{{ 'transactions.list.table.columns.amount' | translate }}</th>
      <th class="text-right">{{ 'transactions.list.table.columns.realAmount' | translate }}</th>
      <th>{{ 'transactions.list.table.columns.status' | translate }}</th>
    </tr>
  </thead>
  <tbody body>
    <tr *ngFor="let transaction of table.config.rows; let i = index">
      <td>{{ i | s1TableGeneralIndex: table }}</td>
      <td>{{ transaction.tsCreated | s1ReadableDate }}</td>
      <td>{{ transaction.tsLastModified | s1ReadableDate }}</td>
      <td class="text-right">{{ transaction.amount | s1Currency: true }}</td>
      <td class="text-right">{{ (transaction.realAmount | s1Currency: true) ?? '-' }}</td>
      <td><leu-transaction-status-badge [status]="transaction.status"></leu-transaction-status-badge></td>
    </tr>
  </tbody>
</s1-table>