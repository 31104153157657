import { Component, Input, OnInit } from '@angular/core';
import { TransactionStatus } from '@app/shared/models/transaction';

@Component({
  selector: 'leu-transaction-status-badge',
  templateUrl: './transaction-status-badge.component.html',
  styleUrls: ['./transaction-status-badge.component.scss']
})
export class TransactionStatusBadgeComponent implements OnInit {

  @Input() status: TransactionStatus;

  styles: { [key in TransactionStatus] : string } = {
    ACTIVE: "badge-success text-white",
    PENDING: "badge-primary text-white",
    CANCELLED: "badge-danger text-white",
    TIMEOUT: "badge-secondary text-white"
  }

  constructor() { }

  ngOnInit(): void { }

}
