<tabset [justified]="true">
    <tab>
        <ng-template tabHeading>
            <em class="icon-equalizer fa-lg"></em>
        </ng-template>
        <h3 class="text-center text-thin mt-4" [innerHTML]="'offsidebar.setting.SETTINGS' | translate"></h3>
        <div class="p-2">
            <h4 class="text-muted text-thin">Layout</h4>
            <div class="clearfix">
                <p class="float-left">Fixed</p>
                <div class="float-right">
                    <label class="switch">
                        <input type="checkbox"
                            [ngModel]="settings.getLayoutSetting('isFixed')"
                            (ngModelChange)="settings.setLayoutSetting('isFixed', $event)" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="float-left">Boxed</p>
                <div class="float-right">
                    <label class="switch">
                        <input type="checkbox"
                            [ngModel]="settings.getLayoutSetting('isBoxed')"
                            (ngModelChange)="settings.setLayoutSetting('isBoxed', $event)" />
                        <span></span>
                    </label>
                </div>
            </div>
            <!--
    <div class="clearfix">
      <p class="float-left">RTL</p>
      <div class="float-right">
         <label class="switch">
            <input type="checkbox" [(ngModel)]="layoutRTL"/>
            <span></span>
         </label>
      </div>
    </div>
    -->
        </div>
        <div class="p-2" *ngIf="!isHorizontal">
            <h4 class="text-muted text-thin">Aside</h4>
            <div class="clearfix">
                <p class="float-left">Collapsed</p>
                <div class="float-right">
                    <label class="switch">
                        <input type="checkbox"
                            [ngModel]="settings.getLayoutSetting('isCollapsed')"
                            (ngModelChange)="settings.setLayoutSetting('isCollapsed', $event)"
                            [disabled]="settings.getLayoutSetting('isCollapsedText')" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="float-left">Collapsed Text</p>
                <div class="float-right">
                    <label class="switch">
                        <input type="checkbox"
                            [ngModel]="settings.getLayoutSetting('isCollapsedText')"
                            (ngModelChange)="settings.setLayoutSetting('isCollapsedText', $event)"
                            [disabled]="settings.getLayoutSetting('isCollapsed')" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="float-left">Float</p>
                <div class="float-right">
                    <label class="switch">
                        <input type="checkbox"
                            [ngModel]="settings.getLayoutSetting('isFloat')"
                            (ngModelChange)="settings.setLayoutSetting('isFloat', $event)" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="float-left">Hover</p>
                <div class="float-right">
                    <label class="switch">
                        <input type="checkbox"
                            [ngModel]="settings.getLayoutSetting('asideHover')"
                            (ngModelChange)="settings.setLayoutSetting('asideHover', $event)" />
                        <span></span>
                    </label>
                </div>
            </div>
            <div class="clearfix">
                <p class="float-left">Show Scrollbar</p>
                <div class="float-right">
                    <label class="switch">
                        <input type="checkbox"
                            [ngModel]="settings.getLayoutSetting('asideScrollbar')"
                            (ngModelChange)="settings.setLayoutSetting('asideScrollbar', $event)" />
                        <span></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="p-2">
            <h4 class="text-muted text-thin">Language</h4>
            <select class="form-control" [ngModel]="selectedLanguage" (ngModelChange)="setLang($event)">
                <option [value]="lang.code" *ngFor="let lang of langs">{{lang.text}}</option>
            </select>
        </div>
    </tab>
    <tab>
        <ng-template tabHeading>
            <em class="icon-user fa-lg"></em>
        </ng-template>
        <h3 class="text-center text-thin mt-4">Connections</h3>
        <div class="list-group">
            <!-- START list title-->
            <div class="list-group-item border-0">
                <small class="text-muted">ONLINE</small>
            </div>
            <!-- END list title-->
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/user/05.jpg" alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                                    <strong>Juan Sims</strong>
                                 </a>
                        <br>
                        <small class="text-muted">Designeer</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-success circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/user/06.jpg" alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                                    <strong>Maureen Jenkins</strong>
                                 </a>
                        <br>
                        <small class="text-muted">Designeer</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-success circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/user/07.jpg" alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                                    <strong>Billie Dunn</strong>
                                 </a>
                        <br>
                        <small class="text-muted">Designeer</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-danger circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/user/08.jpg" alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                                    <strong>Tomothy Roberts</strong>
                                 </a>
                        <br>
                        <small class="text-muted">Designeer</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-warning circle-lg"></span>
                    </div>
                </div>
            </div>
            <!-- START list title-->
            <div class="list-group-item border-0">
                <small class="text-muted">OFFLINE</small>
            </div>
            <!-- END list title-->
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/user/09.jpg" alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                                    <strong>Lawrence Robinson</strong>
                                 </a>
                        <br>
                        <small class="text-muted">Designeer</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-warning circle-lg"></span>
                    </div>
                </div>
            </div>
            <div class="list-group-item list-group-item-action border-0">
                <div class="media">
                    <img class="align-self-center mr-3 rounded-circle thumb48" src="assets/img/user/10.jpg" alt="Image">
                    <div class="media-body text-truncate">
                        <a href="#">
                                    <strong>Tyrone Owens</strong>
                                 </a>
                        <br>
                        <small class="text-muted">Designeer</small>
                    </div>
                    <div class="ml-auto">
                        <span class="circle bg-warning circle-lg"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="px-3 py-4 text-center">
            <!-- Optional link to list more users-->
            <a class="btn btn-purple btn-sm" href="#" title="See more contacts">
                           <strong>Load more..</strong>
                        </a>
        </div>
        <!-- Extra items-->
        <div class="px-3 py-2">
            <p>
                <small class="text-muted">Tasks completion</small>
            </p>
            <div class="progress progress-xs m-0">
                <div class="progress-bar bg-success" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style="width: 80%;">
                    <span class="sr-only">80% Complete</span>
                </div>
            </div>
        </div>
        <div class="px-3 py-2">
            <p>
                <small class="text-muted">Upload quota</small>
            </p>
            <div class="progress progress-xs m-0">
                <div class="progress-bar bg-warning" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style="width: 40%;">
                    <span class="sr-only">40% Complete</span>
                </div>
            </div>
        </div>
    </tab>
</tabset>
