import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { S1Table } from '@app/s1';
import { ICustomer } from '@app/shared/models/customer';
import { CustomerService } from '@app/shared/services/customer.service';

@Component({
  selector: 'leu-customer-cards-section',
  templateUrl: './customer-cards-section.component.html',
  styleUrls: ['./customer-cards-section.component.scss']
})
export class CustomerCardsSectionComponent implements OnInit {

  @Input() set customer(newValue: ICustomer) {
    this._customer = newValue;
    this.idCustomer = this._customer.idUser;
    this.loadCards(this.idCustomer);
  };

  @ViewChild('table') table: S1Table;
  
  _customer: ICustomer;
  idCustomer: number;

  constructor(private customerService: CustomerService) { }

  ngOnInit(): void { }

  loadCards(idCustomer: number) {

    this.customerService.cards(idCustomer).subscribe(cards => {

      this.table.updateData(cards);

    })

  }

}
