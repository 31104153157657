<div *ngIf="result == servicePayResult.OK" class="jumbotron text-center">
  <i class="fas fa-check text-success fa-4x p-4 d-flex flex-column"></i>
  <p class="h3">{{ 'servicePay.ok' | translate: { transactionId: transactionId } }}</p>
  <div class="row">
    <div class="col-12 text-center">
      <s1-button [type]="s1ButtonType.Save" (onClick)="home()" [icon]="'fas fa-home'" [label]="'servicePay.buttons.home'"></s1-button>
    </div>
  </div>
</div>

<div *ngIf="result == servicePayResult.KO" class="jumbotron text-center d-flex flex-column">
  <i class="fas fa-times text-danger fa-4x p-4"></i>
  <p class="h3">{{ 'servicePay.ko' | translate }}</p>
  <div class="col-12 text-center">
    <s1-button [type]="s1ButtonType.Save" (onClick)="home()" [icon]="'fas fa-money'" [label]="'servicePay.buttons.retry'"></s1-button>
  </div>
</div>
