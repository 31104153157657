import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem, IS1SearchParamsJava } from '@app/s1';
import { Status } from '@app/shared/models/status';
import { CenterService } from '@app/shared/services/center.service';

@Component({
  selector: 'leu-center-select',
  templateUrl: './center-select.component.html',
  styleUrls: ['./center-select.component.scss']
})
export class CenterSelectComponent implements OnInit {

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() grouped: boolean = true;
  @Input() readonly: boolean = false;

  centers: IS1InputSelectItem[];

  constructor(private centerService: CenterService) { }

  ngOnInit(): void {
    const params: IS1SearchParamsJava = {
      paging: false,
      orderBy: "id",
      direction: "DESC",
      filters: {
        CENTER: {
          status: Status.ACTIVE
        }
      },
      page: 0,
      rows: 0
    }

    this.centerService.search(params).subscribe(centriPaginati => {

      this.centers = centriPaginati.centri.map(centro => {

        return { code: centro.id, label: centro.name } as IS1InputSelectItem
  
      });

    })
  }

}
