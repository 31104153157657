<s1-modal #modal [title]="'rechargeModal.title'">
  <form [formGroup]="rechargeForm">
    <div class="row">
      <s1-input-text class="col-auto" [control]="rechargeForm.controls.amount" [label]="'rechargeModal.fields.amount'" [appendLabel]="'&euro;'" [textAlignment]="'text-right'" [readonly]="true"></s1-input-text>
      <s1-button [type]="s1ButtonType.Success" [onlyIcon]="true" [icon]="'fas fa-plus'" (onClick)="add(true)"></s1-button>
      <s1-button class="ml-2" [type]="s1ButtonType.Success" [onlyIcon]="true" [icon]="'fas fa-minus'" (onClick)="add(false)" [disabled]="this.rechargeForm.controls.amount.value <= 0"></s1-button>
    </div>
    <div class="row" *ngIf="center?.flgRechargeSPAYG">
      <s1-input-switch class="col-12 col-lg-6" [control]="rechargeForm.controls.servicePay"  label="centers.management.fields.servicePay"></s1-input-switch>
      <div class="col-12 h4 text-danger" *ngIf="!rechargeForm.controls.servicePay.value">{{ "rechargeModal.messages.noServicePay" | translate }}</div>
    </div>
    <div class="row justify-content-center mt-2">
      <s1-button [type]="s1ButtonType.Add" [label]="'rechargeModal.buttons.recharge'" [icon]="'fas fa-cash-register'" (onClick)="recharge()" [disabled]="this.rechargeForm.controls.amount.value <= 0"></s1-button>
    </div>
  </form>
</s1-modal>
