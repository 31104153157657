
const Centers = {
    translate: 'menu.centers',
    link: '/centers',
    icon: 'fas fa-store-alt'
};

const Users = {
    translate: 'menu.users',
    link: '/users',
    icon: 'fas fa-users-cog'
};

const Customers = {
    translate: 'menu.customers',
    link: '/customers',
    icon: 'fas fa-users'
};

const Settings = {
    translate: 'menu.settings',
    link: '/settings',
    icon: 'fas fa-cog'
};

const Dashboard = {
    translate: 'menu.dashboard',
    link: '/dashboard',
    icon: 'icon-speedometer'
};

const Transactions = {
    translate: 'menu.transactions',
    link: '/transactions',
    icon: 'fas fa-file-invoice-dollar'
};

export const menu = {
    SUPERADMIN: [
        Centers,
        Users,
        Customers,
        Settings
    ],
    ADMIN: [
        Centers,
        Users,
        Customers
    ],
    COMPANY: [
        Dashboard,
        Transactions,
        Customers
    ]
};
