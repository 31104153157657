import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { S1UIService } from '@app/s1';
import { ICustomer } from '@app/shared/models/customer';
import { CustomerService } from '@app/shared/services/customer.service';

@Component({
  selector: 'leu-customer-wallet-section',
  templateUrl: './customer-wallet-section.component.html',
  styleUrls: ['./customer-wallet-section.component.scss']
})
export class CustomerWalletSectionComponent implements OnInit {

  @Input() set customer(newValue: ICustomer) {
    this._customer = newValue;
    this.idCustomer = this._customer.idUser;
    this.walletForm.reset();
    this.loadWallet(this.idCustomer);
  };

  _customer: ICustomer;
  idCustomer: number;

  walletForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder, private customerService: CustomerService, private ui: S1UIService) { 

    this.walletForm = this.formBuilder.group({
      code: [null],
      available: [0.00]
    })

  }

  ngOnInit(): void {
  }

  loadWallet(idCustomer: number) {

    this.customerService.wallet(idCustomer).subscribe(wallet => {

      const available = wallet?.available ? (wallet?.available / 100) : 0

      this.walletForm.patchValue({
        code: wallet?.code,
        available: available
      });

    })

  }

}
