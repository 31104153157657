import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { Role } from '@app/shared/models/role';

@Component({
  selector: 'leu-user-role-select',
  templateUrl: './user-role-select.component.html',
  styleUrls: ['./user-role-select.component.scss']
})
export class UserRoleSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() grouped: boolean = true;
  @Input() readonly: boolean = false;

  roles: IS1InputSelectItem[];

  constructor() {

    this.roles = Object.keys(Role).map(key => {

      return { code: key, label: Role[key] } as IS1InputSelectItem

    });

  }

}
