import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SettingsService } from '../../../core/settings/settings.service';

import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import swal from 'sweetalert2';

@Component({
    selector: 'app-recover',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {

    @ViewChild('formRecover') recoverForm: NgForm;

    // string for errorMessage service
    msgTranslation;
    errorTranslation;
    errorMessage;

    constructor(public settings: SettingsService,  private logger: LoggingService, private router: Router, private authService: AuthService, private translate: TranslateService) {
      this.errorTranslation =  this.translate.get('error');
      this.msgTranslation =  this.translate.get('msg');
    }

    onSubmit(): void {
      swal.fire({
        title: this.msgTranslation.value.loading,
        didOpen: function () {
          swal.showLoading();
        }
      });
      this.authService.forgotPwd(this.recoverForm.value.email).subscribe(
        (response) => {

          if (response.outcome.success === true) {
            // link email sent
            swal.fire(this.msgTranslation.value.pwd_sent, this.msgTranslation.value.pwd_sent_ok, "success");
              this.router.navigate(['/login']);
          } else {
            swal.fire(this.msgTranslation.value.error, this.settings.manageErrorMsg(response.outcome), "error").then(function() {
            });
          }
        },
        (error) => {
          this.logger.log("Error", error, 200);
          this.errorMessage = this.errorTranslation.value.generic_error + ". " + this.errorTranslation.value.try_again;
          swal.fire(this.msgTranslation.value.error, this.errorMessage, "error");
        }
      );
    }

    ngOnInit() {

    }

}