import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { S1Modal, S1ButtonType, S1UIService, S1ModalSizes } from '@app/s1';
import { ICenter } from '@app/shared/models/center';
import { ICustomer } from '@app/shared/models/customer';
import { CustomerService } from '@app/shared/services/customer.service';

@Component({
  selector: 'leu-customer-wallet-modal',
  templateUrl: './customer-wallet-modal.component.html',
  styleUrls: ['./customer-wallet-modal.component.scss']
})
export class CustomerWalletModalComponent implements OnInit {

  @ViewChild('modal') modal: S1Modal;

  customer: ICustomer;

  constructor() { }

  ngOnInit(): void { }

  open(customer: ICustomer) {

    this.customer = customer;
    this.modal.open(S1ModalSizes.MD);

  }

  close() {
    this.modal.close();
  }

}
