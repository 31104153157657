import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { LoginData } from '../../../../app/core/classes/loginData.module';

import { LoggingService } from '../../../../app/core/services/log.service';
import { AuthService } from "../../../../app/core/services/auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import swal from 'sweetalert2';
import { TranslatorService } from '@app/core/translator/translator.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: UntypedFormGroup;

    // params from path
    paramId;

    // string for errorMessage service
    msgTranslation;
    errorTranslation;
    errorMessage;

    constructor(
      public settings: SettingsService,
      formBuilder: UntypedFormBuilder,
      private logger: LoggingService,
      private router: Router,
      private route: ActivatedRoute,
      private authService: AuthService,
      private translate: TranslateService,
      private translator: TranslatorService
    ) {
      this.translate.setDefaultLang(this.translator.getLanguageInUse());

      // get text message
      //this.errorTranslation =  this.translate.get('error');
      this.translate.get('error').subscribe( (text) => {
        this.errorTranslation = text;
      });

      //this.msgTranslation =  this.translate.get('msg');
      this.translate.get('msg').subscribe( (text) => {
        this.msgTranslation = text;
      });

      this.paramId = this.route.params.subscribe(params => {
        const id = params['id']; // (+) converts string 'id' to a number
        if (id != null ) { this.errorMessage = this.msgTranslation.logout_ok; }
      });

      this.loginForm = formBuilder.group({
        'username': [null, Validators.required],
        'password': [null, Validators.required]
      });

    }

    onSubmit(): void {
      const lData = new LoginData(this.loginForm.value.username, this.loginForm.value.password);
      swal.fire({
        title: this.msgTranslation.loading,
        didOpen: function () {
          swal.showLoading();
        }
      });
      this.authService.login(lData).subscribe(
        (response) => {
          if (response.outcome.success === true) {
            // login successfull
            swal.close();
            this.router.navigate(['/']);
          } else {
            swal.fire(this.msgTranslation.error, this.settings.manageErrorMsg(response.outcome), "error");
          }
        },
        (error) => {
          this.logger.log("Error", error, 200);
          this.errorMessage = this.errorTranslation.generic_error + " " + this.errorTranslation.try_again;
          swal.fire(this.msgTranslation.error, this.errorMessage, "error");
        }
      );
    }

    ngOnInit() {

    }

}
