import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { S1UIService } from '@app/s1';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeGuard  {

  constructor(private router: Router, private authService: AuthService, private ui: S1UIService){ }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isAdmin() || this.authService.isSuperAdmin()) {
      this.router.navigate(['/centers'])
      return false;
    } else if (this.authService.isCompany()) {
      this.router.navigate(['/dashboard'])
      return false;
    } else {
      this.ui.showPopupNoPermission();
      this.router.navigate(['/login'])
      return false;
    }
    
  }
  
}
