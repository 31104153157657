import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { Status } from '@app/shared/models/status';

@Component({
  selector: 'leu-user-state-select',
  templateUrl: './user-state-select.component.html',
  styleUrls: ['./user-state-select.component.scss']
})
export class UserStateSelectComponent {

  @Input() control: UntypedFormControl;
  @Input() label: string;
  @Input() grouped: boolean = true;
  @Input() readonly: boolean = false;

  status: IS1InputSelectItem[];

  constructor() {

    this.status = Object.keys(Status).map(key => {

      return { code: key, label: Status[key] } as IS1InputSelectItem

    });

  }

}
