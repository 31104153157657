import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { S1Table } from '@app/s1';
import { ICustomer } from '@app/shared/models/customer';
import { CustomerService } from '@app/shared/services/customer.service';

@Component({
  selector: 'leu-customer-transactions-section',
  templateUrl: './customer-transactions-section.component.html',
  styleUrls: ['./customer-transactions-section.component.scss']
})
export class CustomerTransactionsSectionComponent implements OnInit {

  @Input() set customer(newValue: ICustomer) {
    this._customer = newValue;
    this.idCustomer = this._customer.idUser;
    this.loadTransactions(this.idCustomer);
  };

  @ViewChild('table') table: S1Table;

  _customer: ICustomer;
  idCustomer: number;

  constructor(private customerService: CustomerService) { }

  ngOnInit(): void { }

  loadTransactions(idCustomer: number) {

    this.customerService.transactions(idCustomer).subscribe(cards => {

      this.table.updateData(cards);

    })

  }

}
