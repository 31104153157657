import { Injectable } from '@angular/core';
import { Role } from '@app/shared/models/role';

import { menu } from '../../routes/menu';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor() {
        this.menuItems = [];
    }

    addMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
    }>) {
        items.forEach((item) => {
            this.menuItems.push(item);
        });
    }

    getMenu() {

        const roleString = localStorage.getItem("role") as Role ?? Role.COMPANY;

        const role = Object.values(Role).includes(roleString) ? Role[roleString] : Role.COMPANY;

        this.menuItems = menu[role];
        return this.menuItems;
    }

}
