<div class="card shadow-sm" [ngClass]="cardStyle">

  <div class="card-header" [ngClass]="headerStyle" *ngIf="title">
    <div class="row">
      <div class="col-12 text-center">
        <h4 class="d-inline">{{ title | translate | titlecase }}</h4>
        <ng-content select="[header-right]"></ng-content>
      </div>
      <ng-content select="[header-subtitle]"></ng-content>
    </div>
  </div>

  <div class="card-body">
    <ng-content></ng-content>
  </div>

  <div class="card-footer wrapper-empty"><ng-content select="[footer]"></ng-content></div>

</div>