import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { S1UIService } from '@app/s1';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyGuard  {
  
  constructor(private router: Router, private authService: AuthService, private ui: S1UIService){ }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.authService.isCompany()) {
      return true;
    } else {
      this.ui.showPopupNoPermission();
      this.router.navigate(['/'])
      return false;
    }
    
  }
  
}
