import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { S1Modal, S1ButtonType, S1UIService, S1ModalSizes, S1Table } from '@app/s1';
import { ICustomer } from '@app/shared/models/customer';
import { CustomerService } from '@app/shared/services/customer.service';

@Component({
  selector: 'leu-customer-cards-modal',
  templateUrl: './customer-cards-modal.component.html',
  styleUrls: ['./customer-cards-modal.component.scss']
})
export class CustomerCardsModalComponent implements OnInit {

  @ViewChild('modal') modal: S1Modal;

  customer: ICustomer;

  constructor() { }

  ngOnInit(): void { }

  open(customer: ICustomer) {

    this.customer = customer;
    this.modal.open(S1ModalSizes.LG);

  }

  close() {
    this.modal.close();
  }



}
