import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-switch',
  templateUrl: './s1-input-switch.component.html',
  styleUrls: ['./s1-input-switch.component.scss']
})
export class S1InputSwitch {

  @Input() control: UntypedFormControl;
  @Input() label: string = '';  
  @Input() readonly: boolean = false;

  
  constructor() { }



  

}
