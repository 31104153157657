import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './pages/login/login.component';
import { RecoverComponent } from './pages/recover/recover.component';
import { ActivateComponent } from './pages/activate/activate.component';
import { LockComponent } from './pages/lock/lock.component';
import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';
import { AdminGuard } from '@app/shared/guards/admin.guard';
import { HomeGuard } from '@app/shared/guards/home.guard';
import { CompanyGuard } from '@app/shared/guards/company.guard';
import { LoggedGuard } from '@app/shared/guards/logged.guard';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', component: LoginComponent, canActivate: [HomeGuard] },
            { path: 'centers', loadChildren: () => import('./centers/centers.module').then(m => m.CentersModule), canActivateChild: [AdminGuard]},
            { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule), canActivateChild: [AdminGuard] },
            { path: 'customers', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule), canActivateChild: [LoggedGuard] },
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivateChild: [CompanyGuard] },
            { path: 'transactions', loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsModule), canActivateChild: [CompanyGuard] },
            { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canActivateChild: [AdminGuard] },
            { path: 'payments', loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule), canActivateChild: [LoggedGuard] },
        ]
    },

    // Not lazy-loaded routes
    { path: 'login', component: LoginComponent },
    { path: 'login/:id', component: LoginComponent },
    { path: 'recover', component: RecoverComponent },
    { path: 'activate/:id', component: ActivateComponent },
    { path: 'lock', component: LockComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: '404', component: Error404Component },
    { path: '500', component: Error500Component },

    // Not found
    { path: '**', redirectTo: 'home' }

];
