import { Injectable } from '@angular/core';
import { IS1PaginatedResultJava, IS1SearchParamsJava, S1HttpClientService } from '@app/s1';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ICenter } from '../models/center';
import { Status } from '../models/status';
import { ITransaction } from '../models/transaction';

export interface IUpdateCenterStatusParams {
  id?: string;
  status: Status;
}

export interface ICentriPaginati extends IS1PaginatedResultJava {
  centri: ICenter[]
}

export interface ICenterCredit {
  totalCredit: number,
  transactions: ITransaction[]
}

enum CenterApi {
  list = "/list",
  create = "",
  update = "",
  remove = "",
  credit = "/credit"
}

@Injectable({
  providedIn: 'root'
})
export class CenterService {

  private path = "/center"

  constructor(private s1HttpClient: S1HttpClientService) { }

  search(params: IS1SearchParamsJava): Observable<ICentriPaginati> {

    return this.s1HttpClient.post(`${this.path}${CenterApi.list}`, params).pipe(
      map(response => {
        return {
          centri: response.results,
          paginationInfo: { totalItems: response.total }
        } as ICentriPaginati
      })
    )

  }

  detail(centerId: number): Observable<ICenter> {

    return this.s1HttpClient.get(`${this.path}/${centerId}`).pipe(
      map(response => response.data)
    )

  }

  creditDetail(): Observable<ICenterCredit> {

    return this.s1HttpClient.get(`${this.path}${CenterApi.credit}`).pipe(
      map(response => response.data)
    )

  }

  create(params: ICenter): Observable<boolean> {

    return this.s1HttpClient.post(`${this.path}${CenterApi.create}`, params).pipe(
      map(response => response.outcome.success)
    )

  }

  update(params: ICenter): Observable<boolean> {

    return this.s1HttpClient.put(`${this.path}${CenterApi.update}`, params).pipe(
      map(response => response.outcome.success)
    )

  }

  updateStatus(params: IUpdateCenterStatusParams): Observable<boolean> {

    return this.s1HttpClient.put(`${this.path}${CenterApi.update}`, params).pipe(
      map(response => response.outcome.success)
    )

  }

  remove(params: any): Observable<boolean> {

    return this.s1HttpClient.delete(`${this.path}${CenterApi.remove}`, params).pipe(
      map(response => response.outcome.success)
    )

  }
  
}
